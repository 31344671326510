import React, { Component } from 'react'


// IMAGENS ***********
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStroopwafel} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faYoutube, faBehance, faTwitter } from '@fortawesome/free-brands-svg-icons'
library.add(faStroopwafel, faFacebook, faBehance, faInstagram, faYoutube, faTwitter)







export default class Footer extends Component {
  render() {
    return (
        <section id="master_footer">
            <footer>
                    <div className="row">
                        <div className="col-six tab-full pull-right text-center">
                                <ul className="social social-circle text-center">
                                    <li><a href="https://www.facebook.com/Creativediscoverypt/"><i><FontAwesomeIcon icon={['fab', 'facebook']} /></i></a></li>
                                    <li><a href="https://www.youtube.com/channel/UCcuMfx3bRf6X3NXKXaAwb-g"><i><FontAwesomeIcon icon={['fab', 'youtube']} /></i></a></li>
                                    <li><a href="https://www.behance.net/CreativeDiscovery"><i><FontAwesomeIcon icon={['fab', 'behance']} /></i></a></li>
                                    <li><a href="https://www.instagram.com/creativediscoverypt/?hl=pt"><i><FontAwesomeIcon icon={['fab', 'instagram']} /></i></a></li>
                                    <li><a href="https://twitter.com/CreativDiscover"><i><FontAwesomeIcon icon={['fab', 'twitter']} /></i></a></li>
                                </ul>
                    </div>
                    <div className="col-six tab-full">
                        <div className="copyright">
                                <span style={{color: "white"}}>© Descobrimentos Criativos, Lda. All Rights Reserved.</span>          	
                            </div>		                  
                        </div>
                    </div>
                    

            </footer>
        </section>
    )
  }
}
