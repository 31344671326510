import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import ReactCSSTransitionGroup from 'react-transition-group'; // ES6

import designer from './../assets/procura-sedesigner.png'
import marketeer from './../assets/marketeer.png'


import close from './../assets/closeicon.png'

const Close = () => {
    return(
        <Link to="/"><img className="close_detail" src={close} alt="" /></Link>
    )
}

const Designer = ()=>{
    return(
        <div className="div_container">
            <div className="div_content">
                <div className="them sizeable">
                    <h3>O que irás aprender?</h3>
                    <ul>
                        <li>Criação de conteúdo para o digital.</li>
                        <li>Aprofundar o "Know-How".</li>
                        <li>Se quiseres desenvolver as tuas competências no Photoshop, After Effects, Premiere ou até no Lightroom ajudamos-te.</li>
                    </ul>
                </div>
            </div>
            <div className="div_content">
                <div className="them sizeable">
                    <h3>O que esperamos de ti?</h3>
                    <ul>
                        <li>Ser estudante de Design</li> 
                        <li>Ser criativo</li>
                        <li>Ter vontade de entrar no mercado de trabalho. </li>
                        <li>Tens um Blog, uma página no Facebook ou até uma conta no Instagram que estás desenvolver? Mostra-nos, gostaríamos de ir ver.</li>
                    </ul>
                </div>
            </div>
            <div className="div_content --x">
                <Close/>
            </div>
        </div>
    )
}


const Marketing = ()=>{
    return(
        <div className="div_container">
            <div className="div_content">
                <div className="them sizeable">
                    <h3>O que irás Aprender?</h3>
                    <ul>
                        <li>Gestão de Redes Sociais "Planeamento, Criação de conteúdo, etc."</li> 
                        <li>Marketing Digital " S.E.O, Dominar SERPS de forma orgânica, Google Analytics,etc."</li>
                        <li>Marketing de Conteúdo  "É o nosso core."  </li>
                        <li>Se quiseres desenvolver as tuas competências no Photoshop, After Effects, React, Premire ou até no Lightroom ajudamos-te.</li>
                        <li>Como criar artigos e otimizá-los de forma a dominar o Google Search de forma orgânica e a obter um grande alcance nas Redes Sociais. </li>
                    </ul>
                </div>
            </div>
            <div className="div_content">
                <div className="them sizeable">
                    <h3>O que esperamos de ti?</h3>
                    <ul>
                        <li>Ser estudante de Marketing e Comunicação</li> 
                        <li>Ser criativo</li>
                        <li>Ter vontade de entrar no mercado de trabalho.  </li>
                        <li>Tens um Blog, uma página no Facebook ou até uma conta no Instagram que estás desenvolver? Mostra-nos, gostaríamos de ir ver.</li>
                    </ul>
                </div>
            </div>
            <div className="div_content --x">
                <Close/>
            </div>
        </div>

    )
}


const Default = () =>{
    return <div></div>
}


export default class Body extends Component {
    constructor(props){
        super(props)
        this.state={
            jobdetail:false,
            jobstatus:true,
        }
    }
    render() {
        return (
            <Router>
                <main>
                    <section className="sec_container oportunidade_selector">
                            <div className="content">
                                <h2>Oportunidades</h2>
                                <div className="job-button">
                                    <ul className="job-list">
                                        <Link to="/design/"><li className="job"><img src={designer} alt=""/></li></Link>
                                        <Link to="/marketing/"><li className="job"><img src={marketeer} alt=""/></li></Link>                                        
                                    </ul>
                                </div>
                            </div>
                    </section>
                    <section className="sec_container detail closed">
                        <Route path="/" component={Default} />
                        <Route path="/design/" component={Designer} />
                        <Route path="/marketing/" component={Marketing} />
                    </section>
                    <section className="sec_container">
                        <div className="aboutus"> 
                            <h2 className="abouttitle">Quem somos?</h2>
                            <div className="aboutus_content sizeable">
                                <p>
                                    A Creative Discovery é uma agência de jovens criativos que tem a habilidade de descobrir o sucesso de cada marca. Se tens um espírito aventureiro e adoras marcas, então o teu lugar é aqui.
                                    
                                    Trabalhamos com marcas desde de o Palhaço Batatinha até ao Casino do Estoril, por isso prepara-te para libertar horizontes criativos!
                                </p>
                            </div>
                        </div>
                    </section>
                    <section className="sec_container">
                        <div className="call_to_arms">
                            <h3>Estás Curioso?</h3>
                            <p> Gostariamos de saber mais de ti.</p>
                            <a href="mailto:recrutamento@creativediscovery.pt">Envia o teu CV</a>
                        </div>
                    </section>
                </main>
            </Router>
        )
    }
}
