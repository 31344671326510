import React from 'react';
import scrollicon from './../assets/scrollicon.png'



const Header = () => {
    return (
        <header className="App-header">
            <div className="texto-header">
                <div className="text">
                    <h2>Queres Trabalhar com a Creative Discovery? </h2>
                    <p>Vem juntar-te a uma equipa divertida, dinâmica e criativa. Cresce profissionalmente.</p>
                </div>
            </div>
            <span className="see-more"><img src={scrollicon} alt="" /></span>
        </header>
    );
}

export default Header;
